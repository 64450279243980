.Header {
	display:  flex;
	align-content: space-between;
	align-items: center;
	border-bottom:  1px solid #E7E7E7;
	padding:  20px;
	margin-bottom:  50px;
}

.Header__logo {
	width:  120px;
}
