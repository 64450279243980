/* Fonts
-----------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');


/* Resetter
-----------------------------------------------*/

* {
	margin:  0;
	padding:  0;
	box-sizing: border-box;
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	background-repeat: no-repeat;
  	background-position: center center;

}


/* Body
-----------------------------------------------*/

body {
	font-size:  15px;
	font-family: 'Rubik', sans-serif;
	color: #374442;
}

h1 {
	margin-bottom:  30px;
	font-size:  20px;
}

p {
	margin:  30px 0;
}

.container {
	max-width:  1400px;
	margin:  0 auto;
	padding-left:  30px;
	padding-right:  30px;
	display: inherit;
	align-items: inherit;
	justify-content: inherit;
}

.table { 
	width:  100%;
	border-collapse: collapse;
}

.table th, .table td {
	padding:  10px;
	border:  1px solid #E7E7E7;
	text-align: left;
}

.table th {
	border-bottom: 2px solid #333;
}

.table th.r, .table td.r {
	text-align: right;
}


.table tr.country-global td {
	font-weight:  700;
	background-color:  #F8F8F8;
}

.changed {
	background-color:  #FFFFA3 !important;
}


.current-value {
	text-decoration: line-through;
	margin-right:  5px;
	opacity: 0.3;
}

.button {
	background-color:  #E96C21;
	border:  0;
	border-radius: 2px;
	cursor: pointer;
	padding:  10px;
	color:  #FFF;
	font-weight:  500;
	font-size:  15px;
}

.button:hover {
	opacity:  0.9;
}