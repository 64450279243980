
.Footer {
	text-align: center;
	border-top:  1px solid #E7E7E7;
	padding:  20px;
	line-height: 0;
	margin-top:  50px;
}


.Footer__logo {
	width:  150px;
	opacity:  0.15;
	text-align: center;
}

.Footer__logo:hover {
	opacity:  1;
}