
.Message {
	margin:  20px 0 30px;
	font-weight:  500;
	padding:  15px;
	border-radius: 4px;
}


.Message__body {
	display:  flex;
	align-items: center;
}


.Message__icon {
	width:  25px;
	height:  25px;
	margin-right:  10px;
}

.Message--type-error {
	color:  #BC2354;
	background-color:  #F8E9EE;
}

.Message--type-ok {
	color:  #009944;
	background-color:  #E4F1D4;
}


.Message--type-error .Message__icon {
	background-image:  url('error.svg');
}

.Message--type-ok .Message__icon {
	background-image:  url('ok.svg');
}